@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500;600;700&display=swap");

// Note to self, make custom universal import for your SCSS file, one that has all the stuff only you do in erry-project.

body {
  @media (max-width: 768px) {
    background-size: contain;
    background-repeat: repeat;
  }
}

.container {
  @media (min-width: 769px) {
    max-width: 95vw !important;
  }

  @media (min-width: 1200px) {
    max-width: 85vw !important;
  }
}

.transition-all {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.font-oswald {
  font-family: "Oswald", sans-serif;
}

.font-hind {
  font-family: "Hind", sans-serif;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-white {
  color: white;
}

.font-black {
  color: black;
}

.font-caps {
  text-transform: uppercase;
}

.bg-black {
  background-color: black;
}

.bg-white {
  background-color: white;
}

h1 {
  font-size: 4.5rem !important;

  @media (max-width: 768px) {
    font-size: 2.75rem !important;
  }
}

.border-left-none {
  border-left: none !important;
}

.pos-abs {
  position: absolute !important;

  z-index: 2;
}

.btn {
  text-align: center;
  font-size: 1.25rem;
  background-color: rgba(255, 255, 255, 0.1);

  &.white-outline {
    border-style: solid;
    border-width: 2px;
    border-color: white;

    border-radius: 0;
  }

  &:hover {
    transform: scale(1.075);
    background-color: white;
    color: black;
  }
}

section {
  width: 100vw !important;
  max-width: 100vw !important;
  padding-top: 5rem !important;

  min-height: 100vh;
}

.page-container {
  width: 100vw !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
}

.navbar-full {
  min-width: 100vw !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;

  position: fixed;
  z-index: 10;

  .logo-box {
    display: flex;
    align-items: center;
    .img-logo {
      cursor: pointer;
      max-height: 30px;
      filter: contrast(0%) brightness(500%);
    }

    @media (max-width: 768px) {
      min-width: 50% !important;
    }
  }

  .expand-arrow {
    filter: contrast(0%) brightness(500%) !important;
    max-width: 25px;

    &.expanded-nav {
      transform: rotate(180deg) !important;
    }
  }

  .link-shell {
    .nav-link {
      float: right !important;
      font-family: "Hind", sans-serif;
      font-weight: 400;
      color: white;

      cursor: pointer;

      &.active {
        color: rgba(255, 255, 255, 0.4) !important;
        text-decoration: none !important;
      }
    }

    &.m-display {
      max-height: 0vh !important;
      overflow-y: hidden !important;
      .nav-link {
        float: none !important;
        padding: 0 !important;
        margin-top: 0.75rem !important;
        font-size: 1.5rem;
        text-decoration: underline !important;

        &.active {
          color: rgba(255, 255, 255, 0.4) !important;
          text-decoration: none !important;
        }
      }
    }

    &.expanded-nav {
      max-height: 100vh !important;
    }
  }

  &.navbar-black {
    background-color: black;
    opacity: 100 !important;
  }

  &.navbar-transparent {
    background-color: transparent;
    opacity: 0 !important;

    @media (max-width: 768px) {
      .img-logo {
        opacity: 0 !important;
      }

      .expand-arrow {
        opacity: 0 !important;
      }

      .link-shell {
        opacity: 0 !important;
      }
    }
  }
}

.full-home {
  section.intro {
    padding-top: 10rem !important;
    position: relative;

    .abs-dots {
      position: absolute;

      &.one-1 {
        max-width: 8vw;
        z-index: 4;
        transform: translateY(-70%);
        right: 0;
      }

      &.one-2 {
        max-width: 8vw;
        z-index: 0;
        bottom: -10%;
        left: 35%;
      }
    }

    .container {
      height: 100% !important;
    }

    .col-md-auto {
      padding-right: 0 !important;
      position: relative;
      cursor: default !important;

      .typist-inline {
        display: inline !important;

        -webkit-text-stroke: 1px white;
        color: transparent !important;

        &:hover {
          color: white !important;
        }
      }

      .Cursor {
        font-weight: 300 !important;
        padding-left: 0.25rem !important;
      }

      h1 {
        padding: 0 !important;
        margin: 0 !important;
        position: relative;

        span {
          letter-spacing: 0.3rem;
          white-space: nowrap !important;

          &.bg-black {
            background-color: rgba(0, 0, 0, 0.9);
          }
        }
      }
    }

    .col-md {
      padding-left: 0 !important;
      display: flex;
      align-items: center;
      overflow-x: visible;

      img {
        min-width: 120%;

        @media (max-width: 1100px) {
          min-width: 125%;
        }
      }
    }

    @media (max-width: 768px) {
      min-height: 65vh !important;
      padding: 0rem !important;
      background-color: black;
      background-color: black !important;
      background-image: url("./media/topomobile.jpg");
      background-size: cover;

      display: flex;
      align-items: center;

      .container {
        &.m-display {
          position: relative;

          a.btn {
            font-size: 1rem !important;

            background-color: rgba(0, 0, 0, 0.7) !important;

            &.no-outline-left {
              border-left: none !important;
            }

            &:hover {
              background-color: white !important;
            }
          }
        }
      }

      .container-mobile {
        &.m-display {
          padding-left: 1rem;
          .typist-inline {
            display: inline-block !important;
          }

          h1 {
            font-size: 3.25rem !important;
            line-height: 4.25rem !important;

            span {
            }
          }
        }
      }
    }
  }

  section.about {
    .section-header {
      h1 {
        font-size: 4rem !important;
      }
    }

    .other-paragraphs {
      .blurry-div {
        position: relative;
        max-width: 80% !important;
        margin-left: auto;
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        border-style: solid;
        border-width: 3px;
        border-color: white;
        border-top: none;
        border-left: none;

        background-color: rgba(0, 0, 0, 0.8);
        color: white;

        p {
          margin: 0 !important;
        }

        animation: bouncing3 30s alternate infinite;
      }

      @keyframes bouncing3 {
        0% {
          transform: translate(-10%, -5%);
        }
        100% {
          transform: translate(-15%, 2.5%);
        }
      }
    }
    .left-col {
      overflow-x: visible;
      max-width: 45% !important;

      .about-pic {
        max-width: 50vw !important;

        transform: translateX(20%);

        border-style: solid;
        border-width: 43x;
        border-color: white;
        border-top: none;
        border-right: none;

        animation: bouncing 30s alternate infinite;
      }

      @keyframes bouncing {
        0% {
          transform: translate(25%, -5%) scale(0.95);
        }
        100% {
          transform: translate(15%, 5%) scale(1.05);
        }
      }
    }

    .p-col {
      position: relative;
      .p-div-blur {
        position: relative;
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        border-style: solid;
        border-width: 3px;
        border-color: white;
        border-bottom: none;
        border-left: none;

        background-color: rgba(0, 0, 0, 0.8);
      }

      .get-started {
        background-color: white;

        position: absolute;
        right: 0;

        transform: translateX(3px);

        text-decoration: none !important;

        display: flex;
        cursor: pointer;

        border: 3px white solid;

        img {
          max-width: 20px !important;

          animation: bounce 3.5s infinite ease;
        }

        @keyframes bounce {
          0% {
            transform: translate(0, -0.3rem);
          }
          50% {
            transform: translate(0rem, 0.5rem);
          }
          100% {
            transform: translate(-0, -0.3rem);
          }
        }

        div {
          display: flex;
          align-items: center;
          width: 0 !important;
          overflow: hidden !important;
          white-space: nowrap;
          color: white;
        }

        &:hover {
          background-color: black !important;
          img {
            filter: contrast(0%) brightness(500%);
            animation: none;
          }

          div {
            width: 125px !important;
          }
        }
      }
    }
    .section-header {
      position: relative;

      .outline-it {
        -webkit-text-stroke: 1px white;
        color: transparent !important;
        position: absolute;
        top: 0;
        left: 0;

        animation: tilt 3.5s infinite;
      }

      @keyframes tilt {
        0% {
          transform: translate(-0.25rem, 0);
        }
        50% {
          transform: translate(0rem, 0rem);
        }
        100% {
          transform: translate(-0.25rem, 0);
        }
      }
    }

    @media (max-width: 768px) {
      .paragraph-container {
        width: 100% !important;

        background-image: url("./media/mobile-about.jpg");
        background-size: cover;

        border: 2px white solid;
        border-bottom: none;
        border-top: none;
        border-right: none;

        .paragraph-bar {
          -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
          background-color: rgba(0, 0, 0, 0.25);

          border: 2px white solid;
          border-left: none;
          border-bottom: none;

          &.two-bar {
            margin-top: 10rem;
            border: 2px white solid;
            border-left: none;
            display: flex;

            .down-arrow-bar {
              width: 20% !important;
              background-color: transparent;

              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-width: 30% !important;
                filter: contrast(0%) brightness(500%);

                animation: 3.5s tilt-m infinite;
              }

              @keyframes tilt-m {
                0% {
                  transform: translate(-0, -0.5rem);
                }
                50% {
                  transform: translate(0rem, 2rem);
                }
                100% {
                  transform: translate(-0, -0.5rem);
                }
              }
            }

            &:hover {
              .down-arrow-bar {
                background-color: white;

                img {
                  filter: contrast(100%) brightness(100%);
                }
              }
            }

            .get-started-btn {
              width: 100%;

              border: 2px white solid;
              border-bottom: none;
              border-top: none;
              border-right: none;

              font-size: 1.5rem !important;
              color: white !important;
            }

            &:hover {
              .get-started-btn {
                background-color: white;
                color: black !important;
              }
            }
          }
        }
      }
    }
  }

  section.contact {
    .section-header {
      .font-oswald {
        .greyed {
          color: rgba(255, 255, 255, 0.1) !important;
        }
      }

      .contact-dots {
        width: 12vw;
        margin-left: 80%;
        transform: translate(0, 100%);
      }
    }

    .form-box {
      .opacity-none {
        opacity: 0;
      }
      .input-label {
        font-family: "Hind", sans-serif;
        color: white;
        font-weight: 300;
        font-size: 1.45rem;
      }

      input {
        margin-bottom: 2rem;
        border-radius: 0 !important;

        outline: none !important;

        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.6),
          rgba(0, 0, 0, 1),
          rgba(255, 255, 255, 1) 50%
        );
        background-size: 400% 100%;
        background-position: 0% 0%;
        font-family: "Hind", sans-serif;
        color: white;
        font-weight: 300;
        font-size: 1.15rem;
        padding: 0.5rem;

        border: 1px solid white;

        &.color-up {
          animation: 2s color-wave cubic-bezier(0.17, 0.67, 0.44, -0.01)
            forwards;
          cursor: default;
        }
      }

      @keyframes color-wave {
        0% {
          background-position: 0% 0%;
        }
        100% {
          background-position: 100% 0%;
        }
      }

      .message-box {
        position: relative;
        padding: 0 !important;

        .error-highlight {
          position: absolute;
          background-color: white;
          color: black;
          left: 0;
          bottom: 0;
          transform: translateY(100%);
          opacity: 100;

          &.display-none {
            opacity: 0;
          }
        }
        textarea {
          border-radius: 0 !important;
          min-height: 30vh;
          resize: none !important;

          outline: none !important;

          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 1),
            rgba(255, 255, 255, 1) 50%
          );
          background-size: 400% 100%;
          background-position: 0% 0%;
          font-family: "Hind", sans-serif;
          color: white;
          font-weight: 300;
          font-size: 1.15rem;
          padding: 0.5rem;
          margin: 0 !important;

          border: 1px solid white;

          &.color-up {
            animation: 2s color-wave cubic-bezier(0.17, 0.67, 0.44, -0.01)
              forwards;
            cursor: default;
          }
        }

        button {
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: transparent;
          border: 1px white solid;
          font-family: "Hind", sans-serif;
          color: white;
          font-weight: 600;
          font-size: 1.5rem;
          padding: 1rem;
          padding-left: 4.5rem;
          padding-right: 4.5rem;
          margin: 0 !important;

          outline: none !important;

          transform: translateY(-6px);
          transform-origin: right bottom;

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            transform: translateY(-6px) scale(1.2);
          }

          &.submitted {
            background-color: white !important;
            color: black !important;

            &:hover {
              transform: translateY(-6px) !important;
            }
          }

          .checkbox {
            border: 1px solid white;

            border-top: none;
            border-bottom: none;
            border-right: none;
            .check-img {
              filter: contrast(0%) brightness(500%);
              max-width: 20px;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      .font-oswald {
        font-size: 3.5rem !important;
      }
    }
  }

  .highlight-this {
    background-color: white !important;
    color: black !important;
  }
}

.no-display {
  display: none !important;
}

.m-display {
  @media (min-width: 769px) {
    display: none !important;
  }
}

.d-display {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.full-landing {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.5);

  .container {
    height: 100% !important;
    display: flex;
    align-items: center;
  }

  .landing-contents {
    text-align: center;
  }

  .landing-logo {
    filter: contrast(0%) brightness(500%);

    width: 100%;
    max-width: 700px;
  }
}

html {
  background-color: black;
}
